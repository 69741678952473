<template>
  <div>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <a data-widget="pushmenu" href="#" class="nav-link show-on-desktop text-right"><i class="fas fa-arrow-left"></i></a>
      <!-- Brand Logo -->
      <router-link to="/" class="brand-link">
        <div class="login-logo"><img :src="'./images/logo.png'"/></div>
      </router-link>
      <!-- Sidebar -->
      <div class="sidebar">
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image">
              <img :src="'./images/avatar.png'" class="img-circle elevation-2" alt="Rider Image">
          </div>
          <div class="info">
            <a href="#" class="d-block">{{ authUser.name }}</a>
          </div>
        </div>
        <nav>
          <ul class="nav nav-pills nav-sidebar flex-column nav-flat" data-widget="treeview" role="menu" data-accordion="false">
            <li class="nav-item">
              <router-link to="/" class="nav-link active">
                <i class="nav-icon fas fa-tachometer-alt"></i>
                <p>{{ $t('globalTrans.dashboard') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/pickup" class="nav-link">
                <i class="nav-icon fas fa-truck"></i>
                <p>{{ $t('globalTrans.pickup') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/order" class="nav-link">
                <i class="nav-icon fas fa-user-circle"></i>
                <p>{{ $t('globalTrans.order') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="return" class="nav-link">
                <i class="nav-icon fas fa-undo"></i>
                <p>{{ $t('globalTrans.return') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/advanced-salary" class="nav-link">
                <i class="nav-icon fas fa-dollar-sign"></i>
                <p>{{ $t('globalTrans.advanced_salary') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link" @click.prevent="logout">
                <i class="nav-icon fas fa-sign-out-alt"></i>
                <p>{{ $t('globalTrans.sign_out') }}</p>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  </div>
</template>
<script>
import config from '@/config'
export default {
  name:'Sidebar',
  computed: {    
    authUser: function () {
      return this.$store.state.authUser
    }
  },
  methods: {
    logout(){
      this.$router.push('/auth/login');
      config.postData('/rider/logout')
      .then(() => {
        this.$store.dispatch('setAuthUser', '')
        localStorage.removeItem('accessToken');   
      })      
    }
  }
}
</script>